<template>
  <div class="standings">
    <div class="bg-white text-default">
      <div class="row no-gutters padding--bottom-2 padding--top-2">
        <div class="col-auto position-relative results__select">
          <select v-model="year" @change="changeSeason(year)">
            <template v-for="(info, season) in seasons">
              <option :key="'season-' + season"
                      :value="season">{{ season }}</option>
            </template>
          </select>
          <svg class="results__select__icon" fill="fill-bg" height="24px" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve"><g id="arrow" stroke="none" stroke-width="1" fill-rule="evenodd"><g id="Icon-24px" transform="translate(8.000000, 5.500000)"><polygon id="Shape" points="7.41 11.09 2.83 6.5 7.41 1.91 6 0.5 0 6.5 6 12.5"></polygon></g></g></svg>
        </div>
      </div>
      <div v-if="event.isProvisional" class="standings__provision">
        <h4>{{ $t('results.provisionalTextStanding') }}</h4>
      </div>
      <template v-for="rating in wigeData.ratings">
        <div v-if="event && rating in event && event[rating].length" :key="`rating-loop-${rating}`" class="padding--bottom-2">
          <h2>{{ $t('results.' + rating) }}</h2>
          <div class="overflow--auto position-relative">
            <!-- START DRIVER MOBILE -->
            <table width="100%" class="timing-results d-md-none">
              <tbody>
              <template v-for="(row, rowIndex) in event[rating]">
                <tr :key="`${rating}-row-${event.wigeEventId}-${rowIndex}`">
                  <td rowspan="2" class="font-weight--bold text-align--center td-position"
                      :class="wigeData.getRowClass(row)">{{ ('0' + row.position).slice(-2) }}</td>
                  <td class="padding-off padding-right-off" width="60" v-if="!isDTMClassic"><img
                    :src="wigeData.getDriverImage(row, event)"
                    width="60"
                    :alt="row.name">
                  </td>
                  <td class="text-align--center padding-right-off" v-if="!isDTMClassic">
                    <img :src="wigeData.getDriverFlag(row, event)"
                         width="16" height="16"
                         :title="wigeData.getDriverCountryName(row, event)"
                         :alt="wigeData.getDriverCountryName(row, event)">
                  </td>
                  <td class="padding-right text-uppercase" :colspan="!isDTMClassic ? 2 : 4">
                    <router-link
                      v-if="activeRaceSeries.toUpperCase() === 'DTM'"
                      :to="`/${$i18n.locale}/${$t('navigation.driver')}/${wigeData.getDriverSlug(row, event)}`">
                      <div v-html="wigeData.getDriverFullname(row, event)"></div>
                      <div v-html="wigeData.getDriverTeamName(row, event)" style="white-space: normal"></div>
                    </router-link>
                    <div v-if="activeRaceSeries.toUpperCase() !== 'DTM'" v-html="wigeData.getDriverFullname(row, event)"></div>
                    <div v-if="activeRaceSeries.toUpperCase() !== 'DTM'" v-html="wigeData.getDriverTeamName(row, event)" style="white-space: normal"></div>
                  </td>
                </tr>
                <tr :key="`${rating}-row-${event.wigeEventId}--${row.id}`">
                  <td class="text-align--left no-border">
                    <b>{{ $t('results.points') }}</b><br/>
                    <b v-html="row.totalPoints || row.totalpoints"></b>
                  </td>
                  <td class="text-align--left no-border">
                    {{ $t('results.race') }}<br/>
                    {{ row.racepoints }}
                  </td>
                  <td class="text-align--left no-border">
                    {{ $t('results.qualifying') }}<br/>
                    {{ row.qualifyingpoints }}
                  </td>
                  <td class="text-align--left no-border" v-if="!isDTMClassic">
                    {{ $t('results.fastest') }} {{ $t('results.lap') }}<br/>
                    {{ row.fastestlappoints || 0 }}
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
            <!-- END DRIVER MOBILE -->
            <!-- START DRIVER DESKTOP -->
            <table width="100%" class="timing-results d-none d-md-table">
              <thead>
                <tr>
                  <th class="text-condensed d-none d-lg-table-cell" width="60">{{ $t('results.position') }}</th>
                  <th class="text-condensed d-lg-none" width="60">#</th>
                  <th class="text-condensed pa">&nbsp;</th>
                  <th class="text-condensed tl">{{ $t('results.driver') }} / {{ $t('results.team') }}</th>
                  <th class="text-condensed">&nbsp;</th>
                  <th class="text-condensed">&nbsp;</th>
                  <th class="text-condensed">{{ $t('results.points') }}</th>
                  <th class="text-condensed">{{ $t('results.race') }}</th>
                  <th class="text-condensed" :colspan="isDTMClassic ? 2 : 1">{{ $t('results.qualifying') }}</th>
                  <th class="text-condensed lh-11" v-if="!isDTMClassic && seasons[year].fastestlap">{{ $t('results.fastest') }}<br/>{{ $t('results.lap') }} &#9733;</th>
                  <template v-for="(race, raceIndex) in event.races">
                    <th :title="race.name"
                        :key="rating + '-raceindex-' + raceIndex"
                        :class="{ 'padding-left-off': raceIndex > 0 }"
                        class="text-condensed d-none d-lg-table-cell">
                      Q{{ (raceIndex + 1) }}<br/>
                      R{{ (raceIndex + 1) }}
                    </th>
                  </template>
                </tr>
              </thead>
              <tbody>
              <tr v-for="(row, driversIndex) in event[rating]" :key="`${rating}-${driversIndex}`">
                <td class="sticky font-weight--bold text-align--center td-position"
                    :class="wigeData.getRowClass(row)">{{ ('0' + row.position).slice(-2) }}
                </td>
                <td class="text-align--center">
                  <img :src="wigeData.getDriverFlag(row, event)"
                       width="16" height="16"
                       :title="wigeData.getDriverCountryName(row, event)"
                       :alt="wigeData.getDriverCountryName(row, event)">
                </td>
                <td class="sticky padding-right text-uppercase" :class="{ 'padding-left-off': !isDTMClassic }">
                  <router-link
                    :to="`/${$i18n.locale}/${$t('navigation.driver')}/${wigeData.getDriverSlug(row, event)}`"
                    v-if="activeRaceSeries.toUpperCase() === 'DTM'"
                    v-html="`${wigeData.getDriverFullname(row, event)}<br/>${wigeData.getDriverTeamName(row, event)}`"></router-link>
                  <span v-if="activeRaceSeries.toUpperCase() !== 'DTM'" v-html="`${wigeData.getDriverFullname(row, event)}<br/>${wigeData.getDriverTeamName(row, event)}`"></span>
                </td>
                <td class="padding-left-off" v-html="wigeData.getDriverAdditionalRankingIcon(row, event)"></td>
                <td class="text-condensed">
                  <img
                    :src="wigeData.getDriverManufacturerImage(row, event)"
                    alt=""
                    class="imageLight"
                    width="60">
                  <img
                    :src="wigeData.getDriverManufacturerImage(row, event, 'dark')"
                    alt=""
                    class="imageDark"
                    width="60">
                </td>
                <td class="text-align--center"><b v-html="row.totalPoints || row.totalpoints"></b></td>
                <td class="text-align--center" v-html="row.racepoints"></td>
                <td class="text-align--center" v-html="row.qualifyingpoints" :colspan="isDTMClassic ? 2 : 1"></td>
                <td class="text-align--center" v-html="row.fastestlappoints || 0" v-if="!isDTMClassic && seasons[year].fastestlap"></td>
                <template v-for="(race, raceIndex) in row.races">
                  <td :title="race.name"
                      :key="`${rating}-raceindex-${raceIndex}-driver-${driversIndex}`"
                      :class="{ 'padding-left-off': raceIndex > 0 }"
                      class="text-condensed text-align--center d-none d-lg-table-cell font-small">
                    <div :class="{ 'opacity--50': race.qualifyingpoints === '0' }" v-html="race.qualifyingpoints"></div>
                    <div :class="{ 'opacity--50': race.racepoints === '0' }" v-html="`${race.racepoints}${race.fastestlappoints * 1 > 0 && seasons[year].fastestlap ? '<span class=\'font-small\' title=\'Fastest Lap\'> &#9733;</span>' : ''}`"></div>
                  </td>
                </template>
                <template v-for="(race, raceIndex) in event.races">
                  <td
                    :key="`${rating}-raceindex-empty-${raceIndex}-driver-${driversIndex}`"
                    v-if="raceIndex > row.races.length - 1"
                    class="text-condensed text-align--center font-small d-none d-lg-table-cell">&nbsp;
                  </td>
                </template>
              </tr>
              </tbody>
            </table>
            <!-- END DRIVER DESKTOP -->
          </div>
        </div>
      </template>
      <div v-if="event && 'teams' in event && event.teams.length">
        <h2>{{ $t('results.team') }}</h2>
        <div class="overflow--auto position-relative">
          <!-- START TEAMS TABLE -->
          <table width="100%" class="timing-results">
            <thead>
            <tr>
              <th class="text-condensed d-none d-lg-table-cell" width="60">{{ $t('results.position') }}</th>
              <th class="text-condensed d-lg-none" width="60">POS</th>
              <th class="text-condensed tl">{{ $t('results.team') }}</th>
              <th class="text-condensed d-none d-md-table-cell">&nbsp;</th>
              <th width="80" class="text-condensed">{{ $t('results.points') }}</th>
              <template v-for="(race, raceIndex) in event.races">
                <th :title="race.name"
                    :key="'raceindex-' + raceIndex"
                    :class="{ 'padding-left-off': raceIndex > 0 }"
                    class="text-condensed d-none d-lg-table-cell">
                  R{{ (raceIndex + 1) }}
                </th>
              </template>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(team, teamsIndex) in event.teams" :key="`teams-${teamsIndex}`">
              <td class="sticky font-weight--bold text-align--center td-position"
                  :class="wigeData.getRowClass(team)">{{ ('0' + team.position).slice(-2) }}</td>
              <td class="sticky padding-right text-uppercase" style="white-space: normal;" v-html="team.name"></td>
              <td class="d-none d-md-table-cell">
                <img
                  :src="wigeData.getTeamImage(team)"
                  alt=""
                  class="imageLight"
                  width="60">
                <img
                  :src="wigeData.getTeamImage(team, 'dark')"
                  alt=""
                  class="imageDark"
                  width="60">
              </td>
              <td class="text-align--center"><b v-html="team.totalPoints || team.totalpoints"></b></td>
              <template v-for="(race, raceIndex) in team.races">
                <td :title="race.name"
                    :key="`raceindex-${raceIndex}-teams-${teamsIndex}`"
                    :class="{ 'padding-left-off': raceIndex > 0 }"
                    class="text-condensed text-align--center font-small d-none d-lg-table-cell">
                  <div :class="{ 'opacity--50': race.points === '0' }" v-html="race.points"></div>
                </td>
              </template>
              <template v-for="(race, raceIndex) in event.races">
                <td
                  :key="`raceindex-empty-${raceIndex}-teams-${teamsIndex}`"
                  v-if="raceIndex > team.races.length - 1"
                  class="text-condensed text-align--center font-small d-none d-lg-table-cell">&nbsp;
                </td>
              </template>
            </tr>
            </tbody>
          </table>
          <!-- END TEAMS TABLE -->
        </div>
      </div>
      <div class="padding--bottom-2"></div>
      <div v-if="event && 'manufacturers' in event && event.manufacturers.length">
        <h2>{{ $t('results.manufacturer') }}</h2>
        <div class="overflow--auto position-relative">
          <!-- START MANUFACTURERS TABLE -->
          <table width="100%" class="timing-results">
            <thead>
            <tr>
              <th class="text-condensed d-none d-lg-table-cell" width="60">{{ $t('results.position') }}</th>
              <th class="text-condensed d-lg-none" width="60">POS</th>
              <th class="text-condensed tl">{{ $t('results.manufacturer') }}</th>
              <th class="text-condensed d-none d-md-table-cell">&nbsp;</th>
              <th width="80" class="text-condensed">{{ $t('results.points') }}</th>
              <template v-for="(race, raceIndex) in event.races">
                <th :title="race.name"
                    :key="`raceindex-${raceIndex}`"
                    :class="{ 'padding-left-off': raceIndex > 0 }"
                    class="text-condensed d-none d-lg-table-cell">
                  R{{ (raceIndex + 1) }}
                </th>
              </template>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(manufacturer, manufacturersIndex) in event.manufacturers" :key="`manufacturers-${manufacturersIndex}`">
              <td class="sticky font-weight--bold text-align--center td-position"
                  :class="wigeData.getRowClass(manufacturer)">{{ ('0' + manufacturer.position).slice(-2) }}
              </td>
              <td class="sticky padding-right text-uppercase" v-html="manufacturer.name"></td>
              <td class="d-none d-md-table-cell">
                <img
                  :src="wigeData.getManufacturerImage(manufacturer)"
                  alt=""
                  class="imageLight"
                  width="60">
                <img
                  :src="wigeData.getManufacturerImage(manufacturer, 'dark')"
                  alt=""
                  class="imageDark"
                  width="60">
              </td>
              <td class="text-align--center"><b v-html="manufacturer.totalPoints || manufacturer.totalpoints"></b></td>
              <template v-for="(race, raceIndex) in manufacturer.races">
                <td :title="race.name"
                    :key="`raceindex-${raceIndex}-manufacturer-${manufacturersIndex}`"
                    :class="{ 'padding-left-off': raceIndex > 0 }"
                    class="text-condensed text-align--center font-small d-none d-lg-table-cell">
                  <div :class="{ 'opacity--50': race.points === '0' }" v-html="race.points"></div>
                </td>
              </template>
              <template v-for="(race, raceIndex) in event.races">
                <td
                  :key="`raceindex-empty-${raceIndex}-manufacturer-${manufacturersIndex}`"
                  v-if="raceIndex > manufacturer.races.length - 1"
                  class="text-condensed text-align--center font-small d-none d-lg-table-cell">&nbsp;
                </td>
              </template>
            </tr>
            </tbody>
          </table>
          <!-- END MANUFACTURERS TABLE -->
        </div>
      </div>
      <div class="padding--bottom-2"></div>
    </div>
  </div>
</template>

<script>
import AppConst from '../config/app.const';
import Settings from '../config/settings';
import WigeDataModel from '../entity/WigeDataModel';

export default {
  name: 'Standings',
  props: {
    baseEvent: Object,
    raceSeries: String,
    settings: Object,
    headline: String
  },
  data () {
    return {
      event: {},
      wigeData: null,
      seasons: {
        2025: {
          fastestlap: false
        },
        2024: {
          fastestlap: false
        },
        2023: {
          fastestlap: false
        },
        2022: {
          fastestlap: true
        },
        2021: {
          fastestlap: false
        },
        2020: {
          fastestlap: false
        }
      },
      activeRaceSeries: '',
      isDTMClassic: false,
      year: null,
      forceUpdate: false
    };
  },
  computed: {
    queryName: function () {
      return this.$store.getters['page/raceSeriesDrivers'];
    },
    isDtmTrophy: function () {
      return this.$store.getters['page/raceSeries'] === 'dtm-trophy';
    }
  },
  methods: {
    changeSeason (season) {
      this.forceSeason = true;
      this.fetchStandings();
    },
    async fetchDrivers () {
      if (!this.activeRaceSeries) {
        let raceSeries = 'DTM';
        if (this.raceSeries) {
          raceSeries = this.raceSeries.toUpperCase();
        }
        if (this.settings && 'raceSeries' in this.settings) {
          raceSeries = this.settings.raceSeries.toUpperCase();
        }
        this.activeRaceSeries = raceSeries;
      }
      if (this.activeRaceSeries.toUpperCase() === 'DTMCLASSIC' || this.activeRaceSeries.toUpperCase() === 'DRMCUP') {
        this.isDTMClassic = true;
      }
      this.wigeData.setRaceSeries(this.activeRaceSeries);
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=${this.queryName}`,
        lifetime: Settings.LIFETIME_DEFAULT
      }).then(response => {
        this.wigeData.parseDriversResponse(response.drivers);
      });
    },
    async fetchStandings () {
      const now = new Date();
      if (!this.year) {
        this.year = now.getFullYear();
      }
      let startDate = new Date(this.year + '-01-01');
      let endDate = new Date(this.year + '-12-31');
      let raceSeries = 'DTM';
      if (!this.activeRaceSeries) {
        if (this.raceSeries) {
          raceSeries = this.raceSeries.toUpperCase();
        }
        if (this.settings && 'raceSeries' in this.settings) {
          raceSeries = this.settings.raceSeries.toUpperCase();
        }
        this.activeRaceSeries = raceSeries;
      }
      if (!this.forceSeason) {
        if (this.baseEvent) {
          const startTime = new Date(this.baseEvent.startTime);
          const endTime = new Date(this.baseEvent.endTime);
          startDate = new Date(startTime.getFullYear() + '-' + (startTime.getMonth() + 1) + '-' + startTime.getDate());
          endDate = new Date(endTime.getFullYear() + '-' + (endTime.getMonth() + 1) + '-' + endTime.getDate());
          this.year = startDate.getFullYear();
        }
        if (this.settings && 'startDate' in this.settings && 'endDate' in this.settings) {
          startDate = new Date(this.settings.startDate);
          endDate = new Date(this.settings.endDate);
          this.year = startDate.getFullYear();
        }
      }
      this.event = {};
      const schema = this.activeRaceSeries === 'DTMTROPHY' ? 'wigeStandingsTrophy' : 'wigeStandings';
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: 'data?query=' +
          schema +
          '&raceSeries=' +
          this.activeRaceSeries +
          '&startDate=' +
          (startDate.getFullYear() + '-' + ('00' + (startDate.getMonth() + 1)).slice(-2) + '-' + ('00' + startDate.getDate()).slice(-2)) +
          '&endDate=' +
          (endDate.getFullYear() + '-' + ('00' + (endDate.getMonth() + 1)).slice(-2) + '-' + ('00' + endDate.getDate()).slice(-2)),
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          this.wigeData.parseStandingsResponse(response, this.activeRaceSeries);
          this.event = this.wigeData.event;
        })
        .catch(() => {
          //
        });
    }
  },
  created () {
    if (this.settings.seasons) {
      this.seasons = this.settings.seasons;
    }
    // set current year if in url
    const season = parseInt(this.$route.query.season || '');
    if (season && this.seasons[season]) {
      this.year = season;
      this.forceSeason = true;
    }
    this.wigeData = new WigeDataModel();
    this.fetchDrivers();
    this.fetchStandings();
  },
  watch: {
    settings: function (newVal, oldVal) {
      this.fetchStandings();
    }
  }
};
</script>

<style lang="scss">
  .standings {

    &__fold {
      $fold: &;
      position: relative;
      background: $color-bg;
      color: #fff;
      padding: $gap;
      font-weight: 600;
      text-transform: uppercase;
      transition: all .3s;
      font-size: 1.2em;
      cursor: pointer;

      &__icon {
        position: absolute;
        right: $gap;
        transform: rotate(-90deg);
        transition: all .4s $ease-out-back;
      }

      &:hover {
        background: $color-bg-light;
        #{$fold}__icon {
          transform: rotate(-270deg);
        }
      }

      &.view {
        #{$fold}__icon {
          transform: rotate(-270deg);
        }
      }
    }

    &__content {
      position: relative;
      overflow: hidden;
      max-height: 0;
      transition: all 1s $ease-out-cubic;

      &.view {
        transition: all 1s $ease-in-cubic;
        max-height: 5000px;
      }
    }

    &__select {

      &__icon {
        transform: rotate(270deg);
        position: absolute;
        top: 8px;
        right: 6px;
        color: #fff;
        z-index: 0;
      }

      select {
        cursor: pointer;
        position: relative;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        border: 1px solid $color-bg;
        padding: 10px $gap * 5 10px $gap;
        max-width: 100%;
        background: transparent;
        z-index: 1;
        color: $color-bg;
        text-transform: uppercase;

        &::-ms-expand {
          display: none;
        }

        &:focus {
          outline: none;
        }

        option {
          color: $color-bg;
        }
      }
    }
  }
</style>
