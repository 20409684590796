import AppConst from '../config/app.const';
import Settings from '../config/settings';
const WIGE_STANDINGS = 'wigeStandingses';
const WIGE_STANDINGS_TROPHY = 'wigeStandingsTrophies';
const WIGE_RESULTS = 'wigeResultses';
const WIGE_RESULTS_TROPHY = 'wigeResultsTrophies';
const DTMTROPHY = 'DTMTROPHY';
const RACES = 'races';
const IMAGE_TYPE_DEFAULT = 'light';
const BG_PRIMARY_CLASS = 'bg-primary';
const BG_MEDIUM_CLASS = 'bg-medium';
const BG_LIGHT_CLASS = 'bg-light';
const RATINGS = [
  'drivers',
  'Junior',
  'XP',
  'teams',
  'manufacturers',
  'driversKl1',
  'driversKl2',
  'driversKl3',
  'driversKl4',
  'driversKl5',
  'driversKl6',
  'driversKl7',
  'driversKl8',
  'driversKl9',
  'driversKl10',
  'driversKl11'
];

export default class WigeDataModel {
  _drivers = [];
  _driversByUniqueID = {};
  _teams = {};
  _event = {};
  pixel = AppConst.PIXEL;
  _events = {};
  _manufacturers = {};
  _raceSeries = 'DTM';
  _isDTMClassic = false;

  setRaceSeries (raceSeries) {
    this._raceSeries = raceSeries;
    if (this._raceSeries.toUpperCase() === 'DTMCLASSIC' || this._raceSeries.toUpperCase() === 'DRMCUP') {
      this._isDTMClassic = true;
    }
  }

  get ratings () {
    const r = [];
    RATINGS.forEach(entry => {
      const key = entry.indexOf('driversKl') > -1 ? entry : entry.toLowerCase();
      if (key !== 'teams' && key !== 'manufacturers') {
        r.push(key);
      }
    });
    return r;
  }

  get drivers () {
    return this._drivers;
  }

  set drivers (value) {
    this._drivers = value;
  }

  get teams () {
    return this._teams;
  }

  set teams (value) {
    this._teams = value;
  }

  get event () {
    return this._event;
  }

  set event (value) {
    this._event = value;
  }

  get events () {
    return this._events;
  }

  set events (value) {
    this._events = value;
  }

  get points () {
    return {
      2024: {
        race: !this._isDTMClassic ? [25, 20, 16, 13, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1] : [],
        qualifying: !this._isDTMClassic ? [3, 2, 1] : []
      },
      2025: {
        race: !this._isDTMClassic ? [25, 20, 16, 13, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1] : [],
        qualifying: !this._isDTMClassic ? [3, 2, 1] : []
      },
      race: !this._isDTMClassic ? [25, 18, 15, 12, 10, 8, 6, 4, 2, 1] : [],
      qualifying: !this._isDTMClassic ? [3, 2, 1] : []
    };
  }

  get manufacturers () {
    return this._manufacturers;
  }

  set manufacturers (value) {
    this._manufacturers = value;
  }

  parseDriversResponse (responseData) {
    const teamsData = {};
    const driversData = [];
    responseData.forEach((driver) => {
      let age = 0;
      if (driver.appBirth) {
        const appBirthParts = driver.appBirth.split('.');
        const birthDay = new Date(appBirthParts[2], appBirthParts[1] - 1, appBirthParts[0]);
        const ageDifMs = Date.now() - birthDay.getTime();
        const ageDate = new Date(ageDifMs); // miliseconds from epoch
        age = Math.abs(ageDate.getUTCFullYear() - 1970);
      }
      driver.age = age;
      let uniqueID = driver.driverUniqueID;
      if (parseInt(uniqueID.substr(-4)) > 0) {
        uniqueID = uniqueID.substr(0, uniqueID.length - 4);
      }
      // new - driverSeason
      driver.driverSeasons.forEach((driverSeason) => {
        if (driverSeason.team && 'name' in driverSeason.team) {
          const teamName = driverSeason.team.name.toUpperCase();
          if (!(teamName in teamsData)) {
            teamsData[teamName] = driverSeason.team;
          }
        }
        if (driverSeason.teamTrophy && 'name' in driverSeason.teamTrophy) {
          const teamName = `trophy_${driverSeason.teamTrophy.name.toUpperCase()}`;
          if (!(teamName in teamsData)) {
            teamsData[teamName] = driverSeason.teamTrophy;
          }
        }
      });
      if (driver.driverSeasons.length) {
        driversData.push(driver);
      }
    });
    this.drivers = driversData;
    this.teams = teamsData;
    this.manufacturers = this.assignManufacturerData();
  }

  parseStandingsResponse (response, raceSeries) {
    const resultName = raceSeries === DTMTROPHY ? WIGE_STANDINGS_TROPHY : WIGE_STANDINGS;
    if (resultName in response) {
      this.event = {};
      response[resultName].forEach((entry) => {
        this.event = this.createEvent(entry);
      });
    }
  }

  parseResultResponse (response, raceSeries) {
    const responseName = raceSeries === DTMTROPHY ? WIGE_RESULTS_TROPHY : WIGE_RESULTS;
    this.events = {};
    if (responseName in response) {
      const events = {};
      const eventIds = [];
      response[responseName].sort((a, b) => (a.sessionId > b.sessionId ? -1 : b.sessionId < a.sessionId ? 1 : 0));
      response[responseName].forEach((entry) => {
        let event = this.createEvent(entry);
        const jsonData = event.jsonData;
        if (!eventIds.includes(entry.wigeEventId)) {
          eventIds.push(entry.wigeEventId);
          events[event.wigeEventId] = event;
        } else {
          event = events[entry.wigeEventId];
        }
        this.parseEventSessions(event, jsonData);
      });
      const keys = Object.keys(events);
      if (keys.length === 1) {
        events[keys[0]].opened = true;
      } else if (keys.length > 1) {
        events[keys[keys.length - 1]].opened = true;
      }
      this.events = events;
    }
  }

  parseEventSessions (event, jsonData) {
    // sort the sessions reverse (to avoid missing session ids)
    event.sessions.forEach((session, sessionIndex) => {
      if (jsonData && session.sessionid + '' === jsonData.sessionid + '') {
        // set training to qualifying, if qualfying in session name
        if (session.name.toLowerCase().indexOf('qualifying') > -1) {
          session.type = 'qualifying';
        }
        if (session.name.toLowerCase().indexOf('track test') > -1) {
          return true;
        }
        this.parseEventResults(jsonData, session);
        this.setActiveSession(event, jsonData, session);
      }
      return true;
    });
  }

  setActiveSession (event, jsonData, session) {
    session.resultOpen = false;
    session.result = jsonData.result;
    if (jsonData.result && jsonData.result.length) {
      const timeStart = session.timeStart ? new Date(session.timeStart).getTime() : null;
      session.order = session.order || timeStart || session.sessionid;
      if (session.order > event.activeSession) {
        event.activeSession = session.order;
      }
    }
  }

  parseEventResults (jsonData, session) {
    let currentPointValue = 0;
    const date = jsonData.startDate.split('.');
    const points = date.length > 0 && this.points[date[2]] ? this.points[date[2]] : this.points;
    jsonData.result.forEach((result) => {
      // firstName, lastName
      const name = this.formatName(result.name);
      result.firstName = name.firstName;
      result.lastName = name.lastName;
      result.driverUniqueID = this.formatUniqueId(result, session.date.substr(6));
      if (session.type in points) {
        // calculate points, if no sessionpoints
        if (!('sessionpoints' in result)) {
          result.points = 0;
          if (result.status === '' && currentPointValue < points[session.type].length) {
            result.points = points[session.type][currentPointValue];
            currentPointValue++;
          }
        } else {
          result.points = result.sessionpoints;
        }
      }
    });
  }

  sortPositions (a, b) {
    const order = parseInt(a.position) > 0 && parseInt(a.position) > parseInt(b.position) ? 1 : -1;
    return order;
  }

  createEvent (entry) {
    const event = {};
    const date = new Date(entry.startDate);
    event.jsonData = JSON.parse(entry.jsonText);
    event.wigeEventId = entry.wigeEventId;
    event.startDate = entry.startDate;
    event.endDate = entry.endDate;
    event.races = event.jsonData.pointsessions;
    event.year = date.getFullYear();
    event.isProvisional = (event.jsonData.provision && event.jsonData.provision === 'Provisional');
    event.wigeName = entry.wigeName || null;
    event.sessions = event.jsonData.sessions || null;
    event.type = entry.type || null;
    event.activeSession = -1;
    event.opened = false;
    if (event.wigeName) {
      if (event.wigeName.substr(0, 1) === '(') {
        event.wigeName = event.wigeName.substr(event.wigeName.indexOf(')') + 1).trim();
      }
      if (event.wigeName.indexOf('DTM') > -1) {
        event.wigeName = event.wigeName.replace('DTM', '').trim();
      }
    }
    event.manufacturers = event.jsonData.manufacturers ? event.jsonData.manufacturers.sort(this.sortPositions) : [];
    event.teams = event.jsonData.teams ? event.jsonData.teams.sort(this.sortPositions) : [];
    this.ratings.forEach(rating => {
      if (rating in event.jsonData) {
        const key = rating.indexOf('driversKl') > -1 ? rating : rating.toLowerCase();
        event[key] = event.jsonData[rating].sort(this.sortPositions);
        this.assignDriversData(event[key], event.year);
      }
    });
    return event;
  }

  assignDriversData (eventData, year) {
    eventData.forEach((entry) => {
      if (entry) {
        entry.racepoints = 0;
        entry.qualifyingpoints = 0;
        entry.points = 0;
        const name = this.formatName(entry.name);
        entry.firstName = name.firstName;
        entry.lastName = name.lastName;
        entry.driverUniqueID = this.formatUniqueId(entry, year);
        if (RACES in entry) {
          entry.races.forEach((race) => {
            entry.points = parseFloat(race.points.replace(',', '.'));
            entry.racepoints += parseFloat(race.racepoints.replace(',', '.'));
            entry.qualifyingpoints += parseFloat(race.qualifyingpoints.replace(',', '.'));
          });
        }
      }
    });
  }

  assignManufacturerData () {
    const manufacturerObj = {};
    Object.values(this.teams).forEach((team) => {
      if (team && team.manufacturer) {
        manufacturerObj[team.manufacturer.name] = team.manufacturer;
      }
      if (team && team.manufacturerTrophy) {
        manufacturerObj[`trophy_${team.manufacturerTrophy.name}`] = team.manufacturerTrophy;
      }
    });
    return manufacturerObj;
  }

  formatUniqueId (data, year) {
    const firstName = data.firstName.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
    const lastName = data.lastName.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
    let driverUniqueID = firstName + lastName + year;
    driverUniqueID = this.replaceSpecialCharacters(driverUniqueID);
    return driverUniqueID;
  }

  replaceSpecialCharacters (input) {
    return input.replace(/[ä]/g, match => 'ae')
      .replace(/[ü]/g, match => 'ue')
      .replace(/[ö]/g, match => 'oe')
      .replace(/[ó]/g, match => 'o')
      .replace(/[.]/g, match => '')
      .replace(/[ë]/g, match => 'e')
      .replace(/[é]/g, match => 'e');
    // .replace(/[ ]/g, match => '');
  }

  formatName (name) {
    name = name.replace('  ', ' ');
    const fullName = name.split(' ');
    let firstName = '';
    let lastName = name;
    if (fullName.length) {
      firstName = fullName[fullName.length - 1];
      // extra for Jan Philipp, Jose Maria
      if (name.indexOf('Jan Philipp') > -1 ||
        name.indexOf('Jose Maria') > -1 ||
        name.indexOf('Mike David') > -1 ||
        name.indexOf('Rodrigo Dias') > -1) {
        firstName = fullName[fullName.length - 2] + ' ' + fullName[fullName.length - 1];
      }
      lastName = name.substr(0, name.length - firstName.length).trim();
    }
    return { firstName: firstName, lastName: lastName };
  }

  getDriverSlug (data, event) {
    const driver = this.getDriver(data, event);
    return driver && driver.slug ? driver.slug : '';
  }

  getDriverImage (data, event, width = 120) {
    const driver = this.getDriver(data, event);
    return driver && driver.image && driver.image.handle ? `https://${Settings.region}.graphassets.com/${Settings.environmentId}/resize=w:${width},fit:crop/quality=value:70/${driver.image.handle}` : this.pixel;
  }

  getDriverFlag (data, event) {
    const driver = this.getDriver(data, event);
    let flag = this.pixel;
    if (driver && driver.country && driver.country.appCountryFlag) {
      flag = driver.country.appCountryFlag.url;
    }
    if (this._isDTMClassic) {
      if (data.nationality) {
        flag = `/images/countries-dtmclassic/${data.nationality.toUpperCase()}.svg`;
      }
    }
    return flag;
  }

  getDriverCountryName (data, event) {
    const driver = this.getDriver(data, event);
    return driver && driver.country ? driver.country.name : '';
  }

  getDriverFullname (data, event, linebreak = false) {
    const driver = this.getDriver(data, event);
    const firstName = driver && driver.firstName ? driver.firstName : data.firstName;
    const lastName = driver && driver.lastName ? driver.lastName : data.lastName;
    return `${firstName}${(linebreak ? '<br/>' : ' ')}<b> ${lastName}</b>`;
  }

  getDriverTeamName (data, event) {
    const driver = this.getDriver(data, event);
    const teamName = driver && driver.team && driver.team.name ? driver.team.name : driver && driver.teamTrophy && driver.teamTrophy.name ? driver.teamTrophy.name : data.team;
    return `<span class="font-small">${teamName || ''}</span>`;
  }

  getDriverManufacturerImage (data, event, type = IMAGE_TYPE_DEFAULT) {
    const driver = this.getDriver(data, event);
    const manufacturer = driver && driver.manufacturerTrophy
      ? driver.manufacturerTrophy
      : (driver && driver.team && driver.team.manufacturer ? driver.team.manufacturer : driver && driver.teamTrophy && driver.teamTrophy.manufacturer ? driver.teamTrophy.manufacturer : null);
    if (type !== IMAGE_TYPE_DEFAULT) {
      return manufacturer && manufacturer.imageDark ? manufacturer.imageDark.url : this.pixel;
    }
    return manufacturer && manufacturer.image ? manufacturer.image.url : this.pixel;
  }

  getDriverAdditionalRankingIcon (data, event) {
    const driver = this.getDriver(data, event);
    const driverAdditionalEntry = driver && driver.additionalRanking ? driver.additionalRanking : null;
    if (driver && driverAdditionalEntry) {
      if (driverAdditionalEntry === 'XP') {
        return `
           <svg class="results__additional-ranking" xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 49 22">
            <path fill="none" stroke="#00304F" stroke-width="1.5" d="M9.435 21l4.736-5.757L16.75 21h6.835l-4.676-9.925L27.542 1h-7.465L15.91 6.337 13.662 1H6.856l4.347 9.385L2 21h7.435zm23.844 0l1.374-6.297h4.538C46.09 14.703 48 9.816 48 6.907 48 3.639 45.492 1 42.147 1h-10.51l-4.39 20h6.032zm6.202-11.2h-3.454l.646-3.2h3.397c.955 0 1.545.427 1.545 1.341 0 1.189-1.095 1.859-2.134 1.859z"/>
          </svg>
        `;
      }
      if (driverAdditionalEntry === 'Junior') {
        return `
          <svg class="results__additional-ranking" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 17 21">
            <path fill="none" stroke="#00304F" stroke-width="1.5" d="M6.419 20c4.057 0 6.1-2.21 7.094-6.996L16 1h-5.288l-2.46 11.92c-.262 1.316-1.022 2.1-2.226 2.1-.89 0-1.832-.533-2.303-1.148L1 18.153C2.309 19.44 4.508 20 6.419 20z"/>
          </svg>
        `;
      }
      if (driverAdditionalEntry === 'G') {
        return `
          <svg class="results__additional-ranking" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 23 24">
            <g fill="none" fill-rule="evenodd">
              <g fill-rule="nonzero" stroke="#00304F" stroke-width="1.5">
                <path d="M652.623 417.25c4.852 0 5.964 1.773 8.223 4.317h0l-4.422 2.948c-1.662-2.17-2.854-2.29-4.438-2.297h-.346c-1.561 0-2.929.738-3.913 1.922-1.041 1.253-1.658 2.996-1.658 4.932 0 1.369.498 2.769 1.416 3.781.789.869 1.893 1.444 3.254 1.444 1.057 0 1.898-.288 2.497-.919.497-.523.824-1.259 1.01-2.19h0-4.029l.99-3.224h9.51l-1.667 6.817c-2.023 2.477-5.055 3.969-8.543 3.969-2.909 0-5.515-.957-7.369-2.646-1.785-1.628-2.888-3.938-2.888-6.77 0-3.48 1.322-6.5 3.529-8.652 2.199-2.144 5.284-3.432 8.844-3.432z" transform="translate(-639 -416)"/>
              </g>
            </g>
          </svg>
        `;
      }
    }
    return '';
  }

  getDriverTime (data) {
    if (data.status !== '') {
      return data.status;
    } else if (data.position === '1') {
      return data.time;
    }
    return data.gap.indexOf('L') > -1 ? data.gap : data.gap.indexOf('+') > -1 ? data.gap : `+${data.gap}`;
  }

  getTeamImage (data, type = IMAGE_TYPE_DEFAULT) {
    const team = this.getTeam(data);
    const manufacturer = team && team.manufacturer ? team.manufacturer : null;
    if (type !== IMAGE_TYPE_DEFAULT) {
      return manufacturer && manufacturer.imageDark ? manufacturer.imageDark.url : this.pixel;
    }
    return manufacturer && manufacturer.image ? manufacturer.image.url : this.pixel;
  }

  getRowClass (data) {
    if (data.position === '1') {
      return BG_PRIMARY_CLASS;
    }
    if (data.position === '2' || data.position === '3') {
      return BG_MEDIUM_CLASS;
    }
    return BG_LIGHT_CLASS;
  }

  getTeam (data) {
    // 2021 special - RED BULL ALPHATAURI AF CORSE
    let name = this._raceSeries === 'DTM' ? data.name.toUpperCase() : `trophy_${data.name.toUpperCase()}`;
    if (name === 'RED BULL ALPHATAURI AF CORSE') {
      name = 'RED BULL AF CORSE';
    }
    if (name === 'ABT SPORTSLINE') {
      name = 'TEAM ABT SPORTSLINE';
    }
    return this.teams[name];
  }

  formatDriver (driver, driverSeason) {
    // merge together like it was before
    return Object.assign(driver, driverSeason);
  }

  getDriver (data, event) {
    // lets try to save the found driver to data._driver -> then we can skip a lot of loops
    if (data._driver) {
      return data._driver;
    } else {
      let driver = null;
      this.drivers.forEach((d) => {
        if (!driver) {
          const name = `${this.replaceSpecialCharacters(d.lastName.toLowerCase().trim())} ${this.replaceSpecialCharacters(d.firstName.toLowerCase().trim())}`;
          const dataName = this.replaceSpecialCharacters(data.name.toLowerCase().trim());
          let season = null;
          if (name === dataName) {
            season = d.driverSeasons.find((driverSeason) => {
              if (driverSeason.raceSeries.toUpperCase() === this._raceSeries) {
                if (parseInt(driverSeason.season) === parseInt(event.year)) {
                  return true;
                }
              }
              return false;
            });
          }
          if (season) {
            driver = this.formatDriver(d, season);
          }
        }
      });
      if (!driver) {
        this.drivers.forEach((d) => {
          if (!driver) {
            const season = d.driverSeasons.find((driverSeason) => {
              if (driverSeason.raceSeries.toUpperCase() === this._raceSeries) {
                if (event && data.number) {
                  if (parseInt(driverSeason.season) === parseInt(event.year) && parseInt(driverSeason.driverNumber) === parseInt(data.number)) {
                    return true;
                  }
                }
              }
              return false;
            });
            if (season) {
              driver = this.formatDriver(d, season);
            }
          }
        });
      }
      if (driver) {
        data._driver = driver;
      }
      return driver;
    }
  }

  getManufacturerImage (manufacturerData, type = IMAGE_TYPE_DEFAULT) {
    const manufacturer = this._raceSeries === 'DTM' ? this.manufacturers[manufacturerData.name] : this.manufacturers[`trophy_${manufacturerData.name.toUpperCase()}`];
    if (type !== IMAGE_TYPE_DEFAULT) {
      return manufacturer && manufacturer.imageDark ? manufacturer.imageDark.url : this.pixel;
    }
    return manufacturer && manufacturer.image ? manufacturer.image.url : this.pixel;
  }
}
