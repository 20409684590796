<template>
  <div id="app" class="app" ref="app">
    <generic-svg></generic-svg>
    <div class="app__background" :class="{ 'app__background--future-stage': isFutureStage }"></div>
    <Header/>
    <div class="app__content" ref="appContent">
      <router-view/>
    </div>
    <Footer v-if="!isFutureStage && !raceSeriesLoaded"/>
    <future-stage-footer v-if="isFutureStage"/>
  </div>
</template>

<script>
import Header from './components/Header';
import Footer from './components/Footer';
import GenericSvg from './components/layout/GenericSvg';
import AppConst from './config/app.const';
import Settings from './config/settings';
import './styles/global.scss';
import FutureStageFooter from './components/future-stage/FutureStageFooter';

export default {
  name: 'App',
  components: {
    FutureStageFooter,
    Footer,
    Header,
    GenericSvg
  },
  data () {
    return {
      width: 0,
      height: 0,
      scrollTop: -1,
      globals: {}
    };
  },
  computed: {
    isFutureStage: function () {
      return this.$store.getters['page/isFutureStage'];
    },
    userHasPreferences: function () {
      return Object.keys(this.$store.getters['adb2c/userPreferences']).length > 0;
    },
    raceSeriesLoaded: function () {
      return this.$store.getters['page/raceSeriesLoading'];
    }
  },
  methods: {
    // resize callback for window resize
    resize () {
      this.$store.commit('scroll/resize');
    },
    // requestAnimationFrame handler for scrolling and other main topics
    animate () {
      // check current scrollTop and compare against stored scrollTop
      // active again if we won't use smooth-scrollbar
      const scrollTop = this.getScrollTop();
      const scrollTopBefore = this.$store.getters['scroll/scrollTop'];
      if (scrollTop !== scrollTopBefore) {
        // update scrollTop
        this.scrollTop = scrollTop;
        this.$store.commit('scroll/scrollTop', scrollTop);
      }
      window.requestAnimationFrame(this.animate);
    },
    // get the current scrollTop for all kind of browsers
    getScrollTop () {
      let scrollTop = -1;
      if (typeof pageYOffset !== 'undefined') {
        // most browsers except IE before #9
        scrollTop = pageYOffset;
      } else {
        const document = window.document.documentElement; // IE with doctype
        scrollTop = document.clientHeight ? document.scrollTop : window.document.body.scrollTop;
      }
      return scrollTop;
    },
    // smooth-scrollbar scroll
    onScroll (data) {
      if (data.offset.y !== this.scrollTop) {
        this.scrollTop = data.offset.y;
        this.$store.commit('scroll/scrollTop', data.offset.y);
      }
    },
    getFutureStageStatus () {
      return this.$route.name === AppConst.FUTURE_STAGE;
    },
    async fetchGlobals () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: 'data?query=globals',
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          this.$store.commit(AppConst.ADD_GLOBALS, response.globalses[0].i18n);
        });
    }
  },
  watch: {
    '$route' () {
      this.$store.commit('language/ADD_LOCALIZATIONS', {});
      this.$store.commit('page/SET_FUTURE_STAGE', this.getFutureStageStatus());
      // if (this.$msal.isAuthenticated) {
      //   this.$store.dispatch('adb2c/fetchUserPreferences', { userId: this.$msal.userId }).then(() => {
      //     const storageKey = 'redirectedToPreferences';
      //     if (!this.userHasPreferences && !window.localStorage.getItem(storageKey)) {
      //       window.localStorage.setItem(storageKey, 'true');
      //       this.$router.push(`/${this.$i18n.locale}/mydtm/preferences`).catch(() => {});
      //     }
      //   });
      // }
    }
  },
  created () {
    // resize event listener
    window.addEventListener('resize', this.resize);
    this.resize();
    // requestAnimationFrame (normally 60 times per second)
    window.requestAnimationFrame(this.animate);
    this.$store.commit('page/SET_FUTURE_STAGE', this.getFutureStageStatus());
    this.fetchGlobals();
    // if (this.$msal.isAuthenticated) {
    //   this.$store.dispatch('adb2c/fetchUserPreferences', { userId: this.$msal.userId }).then(() => {
    //     if (!this.userHasPreferences) {
    //       this.$router.push(`/${this.$i18n.locale}/mydtm/preferences`).catch(() => {});
    //     }
    //   });
    // }
  }
};
</script>

<style lang="scss">
  :root {
    --font: 'ProximaNova', sans-serif;
    --font-alt: 'Roboto', sans-serif;
    --font-condensed: 'RobotoCondensed', 'Roboto', sans-serif;
    --color-bg: $color-bg;
    --color-primary: $color-primary;
    font-size: 16px;
  }

  @import "styles/reset";
  @import "styles/font";
  @import "styles/bootstrap";

  body {
    font-size: 1rem;
    font-family: var(--font);
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $color-text;
    background: #fff;
    line-height: 1.34em;
    overflow-x: hidden;
  }

  @import "styles/colors";
  @import "styles/fadeIn";
  @import "styles/paddingsMargins";
  @import "styles/images";

  // styles that are yet standalone, but will be outsourced in a scss file until more are together
  .app {
    position: relative;
    width: 100%;
    overflow: hidden;
    transition: color .3s linear, background .3s linear;
    &--dtm-trophy {
      background: $color-primary;
      color: $color-bg;
    }
  }
  .app__content {
    position: relative;
    z-index: +1;
    padding-top: 101px;
  }

  .box-shadow {
    box-shadow: 0 0 20px rgba(0, 0, 0, .4);
  }
  .box-shadow--light {
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
  }
  .border-primary--bottom {
    border-bottom: 10px solid $color-primary;
  }

  .scroll-view {
    height: 100vh;
    width: 100%;
  }
  .container-fluid {
    max-width: 1340px;
  }
  .container-small {
    width: 800px;
    max-width: 100vw;
    margin: 0 auto;
  }
  .position-relative {
    position: relative;
  }
  .position-absolute {
    position: absolute;
  }
  .position-absolute--full {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .position-fixed {
    position: fixed;
  }

  .height-min-100 {
    min-height: 100%;
  }
  .h-0 {
    height: 0;
  }
  .opacity--100 {
    opacity: 1;
  }
  .opacity--75 {
    opacity: .75;
  }
  .opacity--50 {
    opacity: .5;
  }
  .opacity--25 {
    opacity: .25;
  }
  .opacity--0 {
    opacity: 0;
  }

  .play-button {
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 68px;
    height: 68px;
    border-radius: 100%;
    background: rgba(0,0,0,0.3);
    box-shadow: inset 0 0 0 4px #fff, 0 0 20px rgba(#000, .4);
    transition: all .4s $ease-out-cubic;
    svg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      path {
        stroke: none;
        fill: #fff;
        transition: all .4s $ease-out-cubic;
      }
    }
  }
  .play-button__parent {
    cursor: pointer;
  }
  .play-button:hover, .play-button__parent:hover .play-button {
    border-color: $color-primary;
    box-shadow: inset 0 0 0 8px $color-primary, 0 0 20px rgba(#000, .4);
    svg path {
      fill: $color-primary;
    }
  }

  .overflow {
    &--hidden { overflow: hidden; }
    &--auto { overflow: auto; }
    &--visible { overflow: visible; }
  }

  table {
    border-collapse: collapse;
    tr {
      td {
        padding: $gap;
      }
      td, th {
        &.tl {
          text-align: left;
        }
        &.tr {
          text-align: right;
        }
        &.tc {
          text-align: center;
        }
        &.sticky {
          position: sticky;
        }
        &.no-border {
          border: none;
        }
        &.padding-off {
          padding: $gap / 2 $gap 0;
        }
        &.padding-left-off {
          padding-left: 0;
        }
        &.padding-right-off {
          padding-right: 0;
        }
        &.va-center {
          vertical-align: center;
        }
        &.va-top {
          vertical-align: top;
        }
        &.va-bottom {
          vertical-align: bottom;
        }
        &.bg-primary {
          color: $color-bg;
        }
        &.bg-medium, &.bg-light {
          color: #fff;
        }
        &.padding {
          padding: 1px 6px;
        }
        &.padding-right {
          padding-right: 12px;
        }
        &.padding-left {
          padding-right: 12px;
        }
        &.td-flag {
          background-repeat: no-repeat;
          background-size: 18px 18px;
          background-position: $gap center;
          padding-left: $gap * 3;
        }
        &.td-flag--tr {
          background-repeat: no-repeat;
          background-size: 18px 18px;
          background-position: 100% center;
        }
        .imageLight {
          display: none;
        }
        .imageDark {
          display: inline-block;
        }
      }
    }
  }

  .timing-results {
    width: 100%;
    thead {
      background: $color-gray;
      tr {
        td, th {
          font-size: 0.7em;
          font-weight: 400;
          font-style: italic;
          text-transform: uppercase;
        }
      }
    }
    tbody {
      tr {
        transition: all .1s;
        background: #f9f9f9;
        &:nth-child(even) {
          background: #eeeeee;
        }
        td {
          white-space: nowrap;
          vertical-align: center;
          border-top: 4px solid #fff;
          &.no-border {
            border-top: none;
          }
          a {
            transition: all .1s;
            color: $color-bg;
            &:hover {
              color: $color-bg-dark;
            }
          }
        }
        &:hover {
          background: $color-bg;
          color: #fff;
          td {
            .imageLight {
              display: inline-block;
            }
            .imageDark {
              display: none;
            }
            a {
              color: $color-primary;
              &:hover {
                color: #fff;
              }
            }
          }
          .results__additional-ranking {
            path {
              fill: $color-primary-trophy;
            }
          }
        }
      }
    }
  }
  @media (max-width: map_get($grid-breakpoints, 'sm')) {
    .timing-results {
      tbody {
        tr {
          td {
            font-size: 0.8em;

            &.padding-off {
              padding: $gap / 2 0 0 $gap;
            }

            &.td-position {
              font-size: 1em;
              padding: $gap $gap / 2;
            }
          }
        }
      }
    }
  }

  .skew {
    transform: skewY(-2deg);
    overflow: hidden;
    &__container {
      transform: skewY(2deg);
    }
  }

  #comply-consent-manager {
    a {
      color: #00304f !important;
      &:hover {
        color: #ddfe81 !important;
      }
    }
    button.comply-SaveSettingsButton {
      color: #00304f !important;
    }
  }

  @media (max-width: map_get($grid-breakpoints, 'lg')) {
    .app__content {
      padding-top: 67px;
    }
  }

  @import "~swiper/css/swiper.css";
</style>
