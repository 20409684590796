<template>
  <div class="results">
    <div class="bg-white text-default">
      <div class="row no-gutters padding--bottom-2 padding--top-2">
        <div class="col-auto position-relative results__select">
          <select v-model="year" @change="changeSeason(year)">
            <template v-for="(data, season) in seasons">
              <option :key="'season-' + season"
                      :value="season">{{ season }}</option>
            </template>
          </select>
          <svg class="results__select__icon" fill="fill-bg" height="24px" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve"><g id="arrow" stroke="none" stroke-width="1" fill-rule="evenodd"><g id="Icon-24px" transform="translate(8.000000, 5.500000)"><polygon id="Shape" points="7.41 11.09 2.83 6.5 7.41 1.91 6 0.5 0 6.5 6 12.5"></polygon></g></g></svg>
        </div>
      </div>
      <div v-if="lastEvent && lastEvent.isProvisional" class="results__provision">
        <h4>{{ $t('results.provisionalTextResult') }}</h4>
      </div>
      <div class="">
        <h2 class="text-align--center" v-if="headline && Object.keys(wigeData.events).length">{{ headline }}</h2>
        <div v-for="(event, eventCount, index) in wigeData.events" :key="`eventresults-${event.wigeEventId}`">
          <div class="results__fold margin--top-1"
               :class="{ 'view': event.opened }"
               v-if="!customActiveSession"
               @click="event.opened = !event.opened">
            <div class="row">
              <div class="col">
                <span class="text-primary">{{ ('00' + (index + 1)).slice(-2) }}</span>
                {{ event.wigeName }}
              </div>
              <div class="col-auto position-relative">
                <svg class="results__fold__icon svg-fill-white" height="24px" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve"><g id="arrow" stroke="none" stroke-width="1" fill-rule="evenodd"><g id="Icon-24px" transform="translate(8.000000, 5.500000)"><polygon id="Shape" points="7.41 11.09 2.83 6.5 7.41 1.91 6 0.5 0 6.5 6 12.5"></polygon></g></g></svg>
              </div>
            </div>
          </div>
          <div class="results__content" :class="{ 'view': event.opened }">
            <div class="row no-gutters padding--bottom-2 padding--top-2" v-if="!customActiveSession">
              <div class="col-auto position-relative results__select">
                <select v-model="event.activeSession">
                  <template v-for="session in event.sessions">
                    <option v-if="session.result"
                            :key="'sess-' + event.wigeEventId + '-' + session.order"
                            :value="session.order">{{ session.name }}</option>
                  </template>
                </select>
                <svg class="results__select__icon" fill="fill-bg" height="24px" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve"><g id="arrow" stroke="none" stroke-width="1" fill-rule="evenodd"><g id="Icon-24px" transform="translate(8.000000, 5.500000)"><polygon id="Shape" points="7.41 11.09 2.83 6.5 7.41 1.91 6 0.5 0 6.5 6 12.5"></polygon></g></g></svg>
              </div>
            </div>
            <template v-for="session in event.sessions">
              <div v-if="session.result && (customActiveSession || event.activeSession) === session.order"
                   class="padding--bottom-3"
                   :key="`sess-table-${event.wigeEventId}-${session.order}`">
                <!-- START MOBILE -->
                <table class="timing-results d-md-none">
                  <tbody>
                    <template v-for="(row, rowIndex) in session.result.slice(0, resultLimit)">
                      <tr :key="`sess-row-${event.wigeEventId}-${session.order}-${row.id}`" v-if="session.resultOpen || (!session.resultOpen && rowIndex < 5)">
                        <td rowspan="2" class="font-weight--bold text-align--center td-position"
                            :class="wigeData.getRowClass(row)">{{ ('0' + row.position).slice(-2) }}</td>
                        <td class="padding-off" width="60" v-if="!isDTMClassic"><img
                          :src="wigeData.getDriverImage(row, event)"
                          width="60"
                          :alt="row.name"
                        ></td>
                        <td class="text-align--center padding-right-off" v-if="!isDTMClassic">
                          <img :src="wigeData.getDriverFlag(row, event)"
                               width="16" height="16"
                               :title="wigeData.getDriverCountryName(row, event)"
                               :alt="wigeData.getDriverCountryName(row, event)">
                        </td>
                        <td :colspan="session.type === 'qualifying' ? 1 + (isDTMClassic ? 2 : 0) : 2 + (isDTMClassic ? 1 : 0)" class="padding-right text-uppercase">
                          <router-link
                            v-if="activeRaceSeries.toUpperCase() === 'DTM'"
                            :to="`/${$i18n.locale}/${$t('navigation.driver')}/${wigeData.getDriverSlug(row, event)}`">
                            <div v-html="wigeData.getDriverFullname(row, event)"></div>
                            <div v-html="wigeData.getDriverTeamName(row, event)" style="white-space: normal"></div>
                          </router-link>
                          <div v-if="activeRaceSeries.toUpperCase() !== 'DTM'" v-html="wigeData.getDriverFullname(row, event)"></div>
                          <div v-if="activeRaceSeries.toUpperCase() !== 'DTM'" v-html="wigeData.getDriverTeamName(row, event)" style="white-space: normal"></div>
                        </td>
                      </tr>
                      <tr :key="`sess-row-${event.wigeEventId}-${session.order}--${row.id}`" v-if="session.resultOpen || (!session.resultOpen && rowIndex < 5)">
                        <td v-if="session.type === 'race'" class="no-border" colspan="2">
                          <b class="text-uppercase text-condensed"><i>{{ $t('results.time') }}</i></b>
                          <div>{{ wigeData.getDriverTime(row) }}</div>
                        </td>
                        <td class="no-border va-top" :colspan="session.type === 'race' ? 1 : 2">
                          <b class="text-uppercase text-condensed"><i>{{ session.type === 'race' ? $t('results.fastest_lap') : $t('results.time') }}</i></b>
                          <div>{{ row.fastestlap }} <span v-if="row.fastestlappoints > 0" title="Fastest Lap">&#9733;</span></div>
                        </td>
                        <td v-if="session.type === 'qualifying'" class="no-border va-top">
                          <b class="text-uppercase text-condensed"><i>{{ $t('results.interval') }}</i></b>
                          <div>{{ row.int }}</div>
                        </td>
                        <td v-if="session.type === 'training'" class="no-border padding-left-off va-top text-align--center">
                          <b class="text-uppercase text-condensed"><i>{{ $t('results.gap') }}</i></b>
                          <div v-html="row.gap ? row.gap : '&nbsp;'"></div>
                        </td>
                        <td v-if="session.type === 'race' && !isDTMClassic" class="no-border padding-left-off va-top text-align--right">
                          <b class="text-uppercase text-condensed"><i>{{ $t('results.points') }}</i></b>
                          <div :class="{ 'font-weight--bold': row.status === '' }" v-html="row.status !== '' ? row.status : row.points"></div>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
                <!-- END MOBILE -->
                <div class="d-md-none padding--bottom-2">
                  <button class="button-primary button-primary--to-dark w-100" v-if="!session.resultOpen" @click="session.resultOpen = true">more</button>
                </div>
                <!-- START DESKTOP -->
                <table width="100%" class="timing-results d-none d-md-table">
                  <thead>
                  <tr>
                    <th class="text-condensed" width="60">{{ $t('results.position') }}</th>
                    <th class="text-condensed d-none d-lg-table-cell" v-if="!isDTMClassic"></th>
                    <th class="text-condensed" width="40"></th>
                    <th class="text-condensed tl padding-left-off">{{ $t('results.driver') }} / {{ $t('results.team') }}</th>
                    <th class="text-condensed"></th>
                    <th class="text-condensed d-none d-lg-table-cell" v-if="!isDTMClassic">{{ $t('results.manufacturer') }}</th>
                    <th class="text-condensed" v-if="session.type === 'race'">{{ $t('results.time') }}</th>
                    <th class="text-condensed">{{ $t('results.fastest_lap') }}</th>
                    <th class="text-condensed" v-if="session.type === 'race'">{{ $t('results.pitstops') }}</th>
                    <th class="text-condensed" v-if="session.type === 'qualifying'">{{ $t('results.interval') }}</th>
                    <th class="text-condensed" v-if="session.type === 'training'">{{ $t('results.gap') }}</th>
                    <th class="text-condensed" v-if="session.type === 'training'">{{ $t('results.laps') }}</th>
                    <th class="text-condensed" v-if="session.type === 'race' && !isDTMClassic">{{ $t('results.points') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="row in session.result.slice(0, resultLimit)" :key="`sess-row-${event.wigeEventId}-${session.order}-${row.id}`">
                    <td class="font-weight--bold text-align--center td-position"
                        :class="wigeData.getRowClass(row)">{{ ('0' + row.position).slice(-2) }}</td>
                    <td class="padding-off padding-right-off d-none d-lg-table-cell td-flag--tr" width="100" v-if="!isDTMClassic"><img
                      :src="wigeData.getDriverImage(row, event)"
                      width="80"
                      :alt="row.name"
                    ></td>
                    <td class="text-align--center">
                      <img :src="wigeData.getDriverFlag(row, event)"
                           width="16" height="16"
                           :title="wigeData.getDriverCountryName(row, event)"
                           :alt="wigeData.getDriverCountryName(row, event)">
                    </td>
                    <td class="padding-right text-uppercase padding-left-off">
                      <router-link
                        :to="`/${$i18n.locale}/${$t('navigation.driver')}/${wigeData.getDriverSlug(row, event)}`"
                        v-if="activeRaceSeries === 'DTM'"
                        v-html="`${wigeData.getDriverFullname(row, event)}<br/>${wigeData.getDriverTeamName(row, event)}`"></router-link>
                      <span v-if="activeRaceSeries.toUpperCase() !== 'DTM'" v-html="`${wigeData.getDriverFullname(row, event)}<br/>${wigeData.getDriverTeamName(row, event)}`"></span>
                    </td>
                    <td class="padding-left-off" v-html="wigeData.getDriverAdditionalRankingIcon(row, event)"></td>
                    <td class="d-none d-lg-table-cell text-align--center" v-if="!isDTMClassic">
                      <img
                        :src="wigeData.getDriverManufacturerImage(row, event)"
                        alt=""
                        class="imageLight"
                        width="60">
                      <img
                        :src="wigeData.getDriverManufacturerImage(row, event, 'dark')"
                        alt=""
                        class="imageDark"
                        width="60">
                    </td>
                    <td class="text-condensed text-align--center" v-if="session.type === 'race'">
                      {{ wigeData.getDriverTime(row) }}
                    </td>
                    <td :class="{ 'font-weight--bold': row.fastestlappoints > 0 }" class="text-condensed text-align--center">
                      {{ row.fastestlap }} <span v-if="row.fastestlappoints > 0" title="Fastest Lap">&#9733;</span>
                    </td>
                    <td class="text-condensed text-align--center" v-if="session.type === 'race'">{{ row.pitstops }}</td>
                    <td class="text-condensed text-align--center" v-if="session.type === 'qualifying'">{{ row.int }}</td>
                    <td class="text-condensed text-align--center" v-if="session.type === 'training'">{{ row.gap }}</td>
                    <td class="text-condensed text-align--center" v-if="session.type === 'training'">{{ row.laps }}</td>
                    <td class="text-align--center font-weight--bold" v-if="session.type === 'race' && !isDTMClassic">{{ row.points }}</td>
                  </tr>
                  </tbody>
                </table>
                <!-- END DESKTOP -->
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppConst from '../config/app.const';
import Settings from '../config/settings';
import WigeDataModel from '../entity/WigeDataModel';

export default {
  name: 'Results',
  props: {
    baseEvent: Object,
    raceSeries: String,
    settings: Object,
    headline: String
  },
  data () {
    return {
      forceSeason: false,
      seasons: {
        2025: {
          fastestlap: false
        },
        2024: {
          fastestlap: false
        },
        2023: {
          fastestlap: true
        },
        2022: {
          fastestlap: false
        },
        2021: {
          fastestlap: false
        },
        2020: {
          fastestlap: false
        }
      },
      year: null,
      activeRaceSeries: '',
      isDTMClassic: false,
      wigeData: null,
      lastEvent: null
    };
  },
  computed: {
    queryName: function () {
      return this.$store.getters['page/raceSeriesDrivers'];
    },
    customActiveSession: function () {
      return (this.settings && this.settings.activeSession) ? this.settings.activeSession : null;
    },
    resultLimit: function () {
      return (this.settings && this.settings.limit) ? this.settings.limit : 500;
    }
  },
  methods: {
    changeSeason (season) {
      this.forceSeason = true;
      this.fetchResults();
    },
    async fetchDrivers () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=${this.queryName}`,
        lifetime: Settings.LIFETIME_DEFAULT
      }).then(response => {
        this.wigeData.parseDriversResponse(response.drivers);
      });
    },
    async fetchResults () {
      const now = new Date();
      if (!this.year) {
        this.year = now.getFullYear();
      }
      let startDate = new Date(this.year + '-01-01');
      let endDate = new Date(this.year + '-12-31');
      if (!this.activeRaceSeries) {
        let raceSeries = 'DTM';
        if (this.raceSeries) {
          raceSeries = this.raceSeries.toUpperCase();
        }
        this.activeRaceSeries = raceSeries;
      }
      if (!this.forceSeason) {
        if (this.baseEvent) {
          const startTime = new Date(this.baseEvent.startTime);
          const endTime = new Date(this.baseEvent.endTime);
          startDate = new Date(startTime.getFullYear() + '-' + (startTime.getMonth() + 1) + '-' + startTime.getDate());
          endDate = new Date(endTime.getFullYear() + '-' + (endTime.getMonth() + 1) + '-' + endTime.getDate());
          this.year = startTime.getFullYear();
        }
        if (this.settings && 'startDate' in this.settings && 'endDate' in this.settings) {
          startDate = new Date(this.settings.startDate);
          endDate = new Date(this.settings.endDate);
          this.year = startDate.getFullYear();
        }
      }
      if (this.settings && 'raceSeries' in this.settings) {
        this.activeRaceSeries = this.settings.raceSeries.toUpperCase();
      }
      if (this.activeRaceSeries.toUpperCase() === 'DTMCLASSIC' || this.activeRaceSeries.toUpperCase() === 'DRMCUP') {
        this.isDTMClassic = true;
      }
      const query = this.activeRaceSeries === 'DTMTROPHY' ? 'wigeResultsTrophy' : 'wigeResults';
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: 'data?query=' +
          query +
          '&raceSeries=' +
          this.activeRaceSeries +
          '&startDate=' +
          (startDate.getFullYear() + '-' + ('00' + (startDate.getMonth() + 1)).slice(-2) + '-' + ('00' + startDate.getDate()).slice(-2)) +
          '&endDate=' +
          (endDate.getFullYear() + '-' + ('00' + (endDate.getMonth() + 1)).slice(-2) + '-' + ('00' + endDate.getDate()).slice(-2)),
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          this.wigeData.setRaceSeries(this.activeRaceSeries);
          this.wigeData.parseResultResponse(response, this.activeRaceSeries);
          this.lastEvent = this.wigeData.events[Object.keys(this.wigeData.events).pop()];
        })
        .catch(() => {
          //
        });
    }
  },
  created () {
    if (this.settings.seasons) {
      this.seasons = this.settings.seasons;
    }
    // set current year if in url
    const season = parseInt(this.$route.query.season || '');
    if (season && this.seasons[season]) {
      this.year = season;
      this.forceSeason = true;
    }
    this.wigeData = new WigeDataModel();
    this.fetchDrivers();
    this.fetchResults();
  }
};
</script>

<style lang="scss">
  .results {

    &__fold {
      $fold: &;
      position: relative;
      background: $color-bg;
      color: #fff;
      padding: $gap;
      font-weight: 600;
      text-transform: uppercase;
      transition: all .3s;
      font-size: 1.2em;
      cursor: pointer;

      &__icon {
        position: absolute;
        right: $gap;
        transform: rotate(-90deg);
        transition: all .4s $ease-out-back;
      }

      &:hover {
        background: $color-bg-light;
        #{$fold}__icon {
          transform: rotate(-270deg);
        }
      }

      &.view {
        #{$fold}__icon {
          transform: rotate(-270deg);
        }
      }
    }

    &__content {
      position: relative;
      overflow: hidden;
      max-height: 0;
      transition: all 1s $ease-out-cubic;

      &.view {
        transition: all 1s $ease-in-cubic;
        max-height: 5000px;
      }
    }

    &__select {

      &__icon {
        transform: rotate(270deg);
        position: absolute;
        top: 8px;
        right: 6px;
        color: #fff;
        z-index: 0;
      }

      select {
        cursor: pointer;
        position: relative;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        border: 1px solid $color-bg;
        padding: 10px $gap * 5 10px $gap;
        max-width: 100%;
        background: transparent;
        z-index: 1;
        color: $color-bg;
        text-transform: uppercase;

        &::-ms-expand {
          display: none;
        }

        &:focus {
          outline: none;
        }

        option {
          color: $color-bg;
        }
      }
    }
  }
</style>
